<app-jumbotron title="Disclosures and Policies" icon="/assets/images/icons/menu/disclosures.png">   
</app-jumbotron>

<div class="container">
    <div class="row d-flex">
        <div class="col-lg-9 col-md-12 order-lg-1 order-2 p-4 disclosuresInBox">            
            <!-- <h2>Disclosures for the Hypothetical Back-tested Performance of Model Index Portfolios and Indexes</h2> -->
            <!-- <div *ngIf="disclosure$|async" [innerHTML]="(disclosure$|async) | safe:'html'"></div> 
            <div class="p-5 m-5 text-center" *ngIf="!(disclosure$|async)">Loading...</div> -->

            <h2>Disclosures for the Hypothetical Back-tested Performance of Model IFA Index Portfolios and Indexes</h2>
            <hr>
            <p>
                This material is prepared by Index Fund Advisors, Inc. (IFA) for informational purposes only. The performance results provided herein represent the <b>hypothetical back-test</b> of the criteria selected, do not reflect actual trading by IFA and do not represent the actual performance achieved by any IFA client, client account,
                or mutual fund investment. Data is obtained from sources that are believed to be reliable.
            </p>
            <p>
                IFA does not guarantee any minimum level of investment performance or the success of any index portfolio, index, mutual fund or investment strategy. <b>Past performance does not guarantee future results.</b> There is a potential for loss in any investment, including loss of principal invested. All investments involve risk, and
                different types of investments involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that any IFA client account will or is likely to achieve profit or losses similar to those shown in hypothetical back-tested performance. Impacts of federal and state taxes
                and trading costs are not included in the results of index portfolio or index returns. Hypothetical back-tested performance information shown in text, charts, tables, and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any types of securities.
            </p>

            <h3>Overview, Index Funds, IFA Indexes</h3>
            <p>
                The IFA investment strategy is based on principles generally known as Modern Portfolio Theory and the Fama and French Four Factor Model for Equities and Two Factor Model for Fixed Income. IFA Index portfolios are designed to provide substantial global diversification in order to reduce investment concentration and the
                resulting
                potential increased risk caused by the volatility of individual companies, indexes, or asset classes.
            </p>
            <p>
                IFA defines an "index fund" as a mutual fund or exchange traded fund (ETF) that follows a set of rules of ownership which, under normal circumstances, are held constant regardless of market conditions. An important characteristic of an index fund is that its rules of ownership are not based on a forecast of short-term events
                or
                the mispricing of securities. Therefore, an investment strategy that buys and rebalances a portfolio of index funds is often referred to as passive investing, as opposed to active investing.
            </p>
            <p>
                The indexes constructed by IFA (the "IFA Indexes") include several stock and bond indexes that represent a monthly data series that begins with index data from various sources on January 1, 1928. The construction of IFA Indexes data introduces live mutual fund data of funds that are similar to the preceding index upon the
                inception date of the funds and uses that monthly mutual fund data up to the current month.
            </p>
            <p>
                Index portfolios created by IFA (the "IFA Index Portfolios") are allocations of a globally diversified selection of between 11 and 15 IFA Indexes. Each IFA Index Portfolio is assigned a designation number based on the allocation of stock indexes compared to bond indexes within a particular IFA Index Portfolio. For example, the
                IFA Index Portfolio 90 is 90% IFA stock indexes and 10% IFA bond indexes.
            </p>
            <p>
                The data for both the IFA Indexes and the model data for IFA Index Portfolios is hypothetical back-tested performance data that represents a combination of index data and mutual fund data. Please refer to the IFA Indexes Data Sources page at www.ifaindexes.com for additional important information, including a description and
                the time series construction of the underlying indexes and mutual funds relating to each IFA Index. The IFA Index Data Sources, IFA Indexes Time Series Construction
                (see: https://www.ifa.com/disclosures/charts/#timeseries) and several of the Dimensional Indexes (see: https://www.ifa.com/disclosures/charts/#dfafunds) are an integral part of this disclosure and should be read in conjunction with this explanation of the hypothetical back-tested performance of the IFA Indexes and the model
                IFA Index Portfolios, which are allocations of the IFA Indexes. In addition, an extensive glossary of terms used throughout IFA's content, which includes these disclosures, can be found at https://www.ifa.com/glossary/.
            </p>

            <h3>Hypothetical Back-tested Performance</h3>
            <p>
                <span class="number"></span>Hypothetical back-tested performance results have many inherent limitations, some of which are described herein.
            </p>
            <p>
                <span class="number"></span>"Backtesting" is a process of objectively simulating historical investment returns by applying a set of rules for buying and selling securities, and other assets, backward in time, testing those rules, and hypothetically investing in the securities and other assets that are chosen. Backtesting is
                used to assess the
                viability of an investment strategy by building a simulation with historical data to analyze risk and profitability before risking any actual capital and is intended to allow investors to understand and evaluate certain strategies by seeing how they would have performed hypothetically during specific time periods.
            </p>
            <p>
                <span class="number"></span>The hypothetical back-tested performance data comprising the IFA Index data represents a combination of index data and actual mutual fund data. The monthly data series begins with index data on January 1, 1928 and introduces live mutual fund data upon the inception date of each of the mutual funds.
            </p>
            <p>
                <span class="number"></span>The investment strategy of the IFA index portfolios is a buy and hold strategy with annual rebalancing of the index allocation on the first of each year during the relevant time period. The data shown is hypothetical and is provided to illustrate historical risk and return performance had the IFA
                Indexes and IFA Index
                Portfolios been available over the relevant time period shown.
            </p>
            <p>
                <span class="number"></span>IFA did not offer the IFA Index Portfolios until November 1999. Prior to November 1999, IFA did not manage client assets.
            </p>
            <p>
                <span class="number"></span>All performance results of the IFA Indexes and IFA Index Portfolios are based on performance of indexes in the IFA Index Portfolios. The hypothetical back-tested performance was achieved with the benefit of hindsight; it does not represent actual investments in any investment strategies.
            </p>

            <h3><span class="number"></span>Limitations of Hypothetical Performance Data</h3>
            <p>
                Hypothetical performance is significantly different from actual investment performance. There are certain limitations inherent in hypothetical model results like those portrayed, which include, among others, that such hypothetical model returns: (a) do not reflect trading in actual client accounts, (b) are dependent on
                the market and economic conditions that existed during the relevant period and do not reflect the impact that material economic and market factors may have had on the adviser's decision-making had the adviser actually been managing client funds, (c) reflect simulated trading programs which, in general, benefit compared
                to actual performance results because such simulated programs are designed with the benefit of hindsight, (d) assume that the securities used in the hypothetical back-tested results were available for purchase or sale during the time period presented and the markets were sufficiently liquid to permit the types of
                trading used, and (e) do not involve or take into account financial risk and do not take into account that material and market factors may have impacted IFA's decision making, all of which can adversely affect actual trading results and performance. For example, the ability to withstand losses or adhere to a particular
                trading program in spite of trading losses are material points which can also adversely affect markets in general or the implementation of any specific trading program. Hypothetical back-tested performance does not represent actual performance, actual trading costs or the actual impact of taxes and should not be
                interpreted as an indication of such performance.
            </p>
            <p>
                <span class="number"></span>Hypothetical back-tested performance also differs from actual performance because it is achieved through the retroactive application of model index portfolios designed with the benefit of hindsight. As a result, the IFA Index Portfolios may be changed from time to time and the effect on hypothetical
                performance results
                could be either favorable or unfavorable.
            </p>
            <p>
                <span class="number"></span>Hypothetical back-tested performance is calculated by using a software program that starts with the first day of a selected month and ends with the last day of a selected month. Whenever the term IFA Index Portfolio value data is used, it is based on a starting value of one at the beginning of
                stated time period.
            </p>
            <p>
                <span class="number"></span>Hypothetical back-tested performance results for IFA Index Portfolios are based on a buy and hold strategy, with annual rebalancing on the first of each year. It is important to understand that the assumption of first of the year annual rebalancing has an impact on the monthly returns reported for
                IFA Index Portfolios
                throughout the year. If there were monthly rebalancing instead, the monthly return would be calculated with the assumption that the portfolio is in balance at the beginning of each month. For annual rebalancing, the year-to-date and monthly return is calculated with the assumption that the portfolio is in balance only
                at the beginning of each year. In actual client portfolios, however, accounts are reviewed quarterly, and rebalancing occurs as needed. Generally, rebalancing events are recommended by IFA when a client portfolio exceeds the applicable variance threshold assigned by IFA to each IFA Index Portfolio, and rebalancing is
                implemented with client approval. Rebalancing actions are dependent on both market conditions and individual client cash inflows and outflows, along with the cost impact of such transactions on the overall client portfolio. Trades for the hypothetical returns were not actually executed.
            </p>
            <p>
                <span class="number"></span>Hypothetical back-tested performance results for IFA Index Portfolios does include the reinvestment of dividends and capital gains and is shown net of IFA's highest advisory fee of 0.90%, and net of mutual fund fees (actual mutual fund fee amounts are deducted when available, otherwise, otherwise,
                mutual fund fees are
                estimated). Simulated index data, retroactively calculated by the applicable mutual fund company (e.g., Dimensional Fund Advisors (DFA)) or research data source (Fama/ French, is used for the period prior to the inception of the relevant live mutual fund data and a mutual fund expense ratio is deducted from such
                simulated index data. The impacts of trading costs are not included in the performance results and will reduce client performance. In the hypothetical performance figures shown, a monthly advisory fee of 0.075% (which amounts to 0.90% annually) is deducted from month end returns, unless stated otherwise. However,
                actual client advisory fees are deducted quarterly, in advance by IFA. Depending on the amount of assets under management and other factors, investment management fees paid by a client may be less (please see IFA's Form ADV Part 2 Brochure for additional information). Note that a client's return will be reduced by the
                amount of advisory fees charged by IFA and any other expenses, and the inclusion of IFA's advisory fees will have a negative impact on client account performance. IFA accepts no fees from investment product firms.
            </p>
            <p>
                <span class="number"></span>There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical back-tested performance results and all of which can adversely affect actual trading results.
            </p>

            <h3>Performance Results and Composition of IFA Indexes and IFA Index Portfolios</h3>

            <h4>IFA Index Portfolios</h4>
            <p>
                <span class="number"></span>Investments cannot be made directly in an index. Past performance is no guarantee of future results.
            </p>
            <p>
                <span class="number"></span>Performance results for actual clients that invest in accordance with the IFA Index Portfolio models will vary from the back-tested performance due to the use of mutual funds for implementation that differ from those mutual funds underlying the IFA Index data, current market conditions, investments
                cash flows, mutual fund
                allocations, changing index allocations over time, frequency and precision of rebalancing, not following IFA's advice, retention of previously held securities, tax loss harvesting and glide path strategies, actual cash balances, lower advisory fees, varying custodian fees, and/or the timing of fee deductions. Tax liabilities
                will vary for each client and can result from various activities in taxable and tax-deferred accounts. These activities include, but are not limited to rebalancing of portfolios, any sale of securities, tax loss harvesting, interest, dividends and capital gains distributions from equity funds and individual securities in
                taxable accounts. There are also tax liabilities associated with distributions from tax-deferred accounts. Not all IFA clients follow IFA's recommendations and depending on unique and changing client and market situations, IFA may customize the construction and implementation of the IFA Index Portfolios for particular clients
                so that actual client accounts differ materially from those shown. IFA provides many index portfolio implementation strategies, which include, among others, the use of exchange-traded funds (ETFs), global extended maturity bond funds, municipal bond funds, social or sustainable screens added to funds, diversified portfolios of
                various index fund providers, and use of core funds or global asset allocation funds. These many implementation options for IFA Index Portfolios are expected to have risks and potential returns that vary from the IFA Index Portfolio models. As a result of these and other variances, actual performance for client accounts has
                been and are likely to be materially different and may be lower than the results shown in the IFA Index Portfolio models. Clients should consult their account statements for information about how their actual performance compares to that of the IFA Index Portfolios and ask your IFA Wealth Advisor to explain any differences.
            </p>

            <h4>IFA Indexes</h4>

            <p>
                <span class="number"></span>The underlying indexes and mutual funds used in constructing the IFA Indexes are IFA's best estimate of an underlying index or mutual fund that comes closest to the corresponding IFA Index objectives. Simulated index data, retroactively calculated by the applicable mutual fund company (e.g.,
                Dimensional Fund Advisors (DFA)) or research data source (Fama/ French, is used for the period prior to the inception of the relevant live mutual fund data and a mutual fund expense ratio is deducted from such simulated index data. For example, where a DFA or Fama/ French index serves as the underlying index, DFA or Fama/
                French, respectively, calculate the simulated index data used by IFA for the corresponding IFA Index. Such simulated index data does not reflect actual mutual fund data prior to the inception date of the mutual fund comprising the IFA Index. Accordingly, the results shown during the periods prior to the inception date of a
                mutual fund do not represent actual returns of the IFA Index. Periods selected other than those shown may have different results, including losses.
            </p>
            <p>
                The launch date for each mutual fund used in creating the IFA Indexes may be found in the description of each IFA Index here: https://www.ifa.com/disclosures/index-data/.
            </p>
            <p>
                Live (or actual) mutual fund performance data is used after the date each mutual fund was added to the IFA Indexes. The IFA Indexes Times Series Construction goes back to January 1928, with an increasing diversification to international markets, emerging markets and real estate investment trusts as data became available over
                time. As of January 1928, there are four equity IFA Indexes and two bond IFA Indexes used to construct the IFA Index Portfolios; in January 1970 there are a total of 8 IFA Indexes, and there are 15 IFA Indexes in March 1998 to present used to construct the IFA Index Portfolios. For additional details and to see the analysis of
                the evolution of these IFA Index Portfolios, see: https://www.ifa.com/disclosures/charts/#IFA_evolution.
            </p>
            <p>
                IFA Indexes are unmanaged however a mutual fund expense ratio has been deducted from each of the IFA Index returns.
            </p>
            <p>
                <span class="number"></span>The following summarizes the history of changes made to the IFA Indexes and IFA Index Portfolios: 1992-2000: IFA's original Index Portfolios 20, 40, 60, and 80 (the number refers to the percentage of equity (stock) indexes versus bond indexes in the allocation) were suggested by DFA in 1992
                (ifa.com/pdfs/1992.pdf), as an example of globally diversified asset classes, with moderate modifications in 1995 (ifa.com/pdfs/1995.pdf). Twenty IFA Index Portfolios 5 to 100 were created by IFA in 2000, as a lower and higher extension of the DFA 1992 risk and return options. There are numerous other changes that occurred
                relating to the IFA Index Portfolios from 2002 to present, which include, among other things, changes to performance calculations and associated returns (which resulted in returns of the IFA Index Portfolios being both higher and lower, depending on the particular IFA Index Portfolio), and they are described on
                www.ifa.com/disclosures/history/.
            </p>

            <h3>Public Market Index Disclosures:</h3>
            <p><b>Investments cannot be made directly in an index. Past performance is no guarantee of future results.</b></p>
            <p>
                <span class="number"></span>Performance of the IFA Index Portfolios should not be compared directly to any public market indexes. Correlation of a portfolio with an index will vary upon different factors including fixed income portion, market sector and international exposure. IFA will provide additional disclosure where
                direct comparisons to public market indexes are made. Reference to any public market indexes is not intended to and does not imply or suggest that any of the IFA Index Portfolios will achieve returns, experience volatility or have other results similar to such indexes.
            </p>

            <h3>Information About Index Fund Advisors, Inc.</h3>
            <p>
                <span class="number"></span>IFA is an SEC registered Investment Adviser. Information pertaining to IFA's advisory operations, services, and fees is set forth in IFA's current Form ADV Part 2 (Brochure) which is available upon request and at https://adviserinfo.sec.gov/firm/summary/109143 IFA is not paid any brokerage
                commissions, sales loads, 12b-1 fees, or any form of compensation from any mutual fund company, exchange-traded fund company or broker dealer. The only source of compensation relating to IFA client investments is obtained from asset-based advisory fees paid by clients (note that, unrelated to IFA's investment management
                services, IFA also receives tax or accounting related fees paid to IFA's division providing such tax or accounting services. Tax and accounting services are provided under a separate written agreement). More information about advisory fees, expenses, mutual fund fees, and prospectuses for mutual funds can be found at
                https://www.ifa.com/fees/.
            </p>

            <h3>Certain Associated Risks</h3>
            <p>
                <span class="number"></span>IFA Index Portfolios will be implemented for clients by investing in an allocation of mutual funds and/or exchange-traded funds (ETFs) that match the asset classes, mainly (but not exclusively) funds from DFA. All mutual funds and ETFs carry risks, and those risks can vary depending on the
                underlying investments and the fund's investment strategy. IFA Index Portfolios are numbered from 1 to 100 based on the percentage allocation to equity indexes. IFA Index Portfolios with lower equity allocations and higher bond allocations generally have less risk, as measured by standard deviation, than those with higher
                equity allocations and lower bond allocations. There is risk of loss in any securities investment, including the risk of loss of principal that the client should be prepared to bear. Clients are provided with a copy of each mutual fund and ETF prospectus, which outlines the risks associated with the fund and should be read
                carefully. There is no guarantee that any IFA Index Portfolio will meet its investment objectives.
            </p>

            <h3>Standard Deviation Information</h3>
            <p>
                <span class="number"></span>IFA utilizes standard deviation as a quantification of risk. Standard deviation is a statistic that measures the dispersion of a dataset relative to its mean (also called an average) and is a common measure of risk used by academics, analysts, portfolio managers and advisors. The higher the standard
                deviation, the higher the risk. Standard deviation is a measure of the dispersion of a dataset relative to its average and is calculated as the square root of the variance of the data from the average. If data points are far from the average, there is a higher deviation within the data set; thus, the more spread out the data,
                the higher the standard deviation. In finance, standard deviation is applied to the rate of return of an investment to measure the investment's volatility.
            </p>
            <p>
                Standard deviation is also known as historical volatility and is used by investors as a gauge for the amount of expected volatility or the uncertainty of expected returns. For example, among indexes of stocks, those indexes comprised of smaller companies, international companies and emerging market companies generally have had
                higher standard deviations than those indexes comprised of large companies in the U.S. over long time periods. As another example, among bond indexes, those bond indexes with longer durations and greater probabilities of default have had higher standard deviations over long time periods. However, it is not true that all
                indexes with higher standard deviations, such as those indexes comprised of small growth companies, have had higher returns over long time periods.
            </p>
            <p>
                Annualized standard deviation approximates standard deviation over a period of one or more years and, is calculated by multiplying the standard deviation by the square root of the number of periods in one year. By way of example, the annualized standard deviation for a period of one year is calculated by multiplying the
                monthly standard deviation by the square root of 12, which is 3.46. In those charts and tables where the annualized standard deviation of daily returns is shown, it is estimated as the standard deviation of monthly returns divided by the square root of 22, which is 4.69
            </p>
            <p>
                Please note that the annualized standard deviation number computed from annual data may differ materially from the estimate obtained from monthly data. IFA has chosen this methodology because Morningstar uses the same method.
            </p>

            <h3>Data Source Information</h3>
            <p>
                <span class="number"></span>IFA licenses data, in part, from Morningstar Direct, a third-party provider of stock market data. Where data is cited from Morningstar Direct, the following disclosures apply: &copy;2022 Morningstar, Inc. All rights reserved. The information provided by Morningstar Direct and contained herein: (1) is
                proprietary to Morningstar and/or its content providers; (2) may not be copied or distributed; and (3) is not warranted to be accurate, complete, or timely. Neither Morningstar nor its content providers are responsible for any damages or losses arising from any use of this information. IFA Index Portfolios, times series,
                standard deviations, and returns calculations are derived using IFA proprietary calculation methods, which apply rebalancing rules, monthly fee adjustments and creates time series construction of data. Our source data comes from many places including Dimensional Fund Advisors and Morningstar Direct software as indicated in the
                relevant tables and charts.
            </p>

            <h3>Disclaimer</h3>
            <p>
                <span class="number"></span>DISCLAIMER: THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED, AS TO ACCURACY, COMPLETENESS, OR RESULTS OBTAINED FROM ANY INFORMATION PROVIDED HEREIN OR ON THE MATERIAL PROVIDED.
            </p>
            <p>This document and the information which it accompanies or to which it refers and relates does not constitute a complete description of IFA's investment services and is for informational purposes only. It is in no way a solicitation or an offer to buy or sell securities or investment advisory services. Any statements regarding
                market or other financial information are obtained from sources which IFA and its suppliers believe to be reliable, but IFA does not warrant or guarantee the timeliness or accuracy of this information. Neither IFA's information providers nor IFA shall be liable for any errors or inaccuracies, regardless of cause, or the lack
                of timeliness of, or for any delay or interruption in the transmission thereof to the user. All investments involve risk, including foreign currency exchange rates, political risks, market risk, different methods of accounting and financial reporting, and foreign taxes. Your use of these and all materials provided by IFA,
                including the www.ifa.com website and the IFA App is your acknowledgement that you have read and understood the full disclaimer as stated above. For additional updates please refer to www.ifabt.com.</p>

            <p>
                <span class="number"></span>This material is intended to be informational in nature and should not be construed as tax advice. As a division of IFA, IFA Taxes provides a wide array of tax planning, accounting and tax return preparation services for individuals and businesses across the United States. IFA Taxes does not provide
                auditing or attestation services and therefore is not a licensed CPA firm. IRS Circular 230 Disclosure: To ensure compliance with requirements imposed by the IRS, we inform you that any U.S. Federal tax advice contained in this communication is not intended or written to be used, and cannot be used, for the purpose of (i)
                avoiding penalties under the Internal Revenue Code or (ii) promoting, marketing, or recommending to another party any transaction or matter herein.
            </p>
            <p class="text-end"><i>Last Updated 11-02-2022</i></p>

        </div>
        <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
            <app-side-nav-disclosures></app-side-nav-disclosures>
        </div>
    </div>
</div>

<app-jumbotron title="History of Changes to IFA Indexes and IFA Index Portfolios"
  icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>
<div class="container">
  <div class="row d-flex">

    <div class="col-lg-9 col-md-12 order-lg-1 order-2 p-4">
      <p>List of Changes:</p>
      <div class="row">
        <ul class="col-md-3 indented-list">
          <li><a [routerLink]="['/disclosures/history']" fragment="2002-11">November 2002</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2004-01">January 2004</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2006-06">June 2006</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2008-01">January 2008</a></li>
         
        </ul>
        <ul class="col-md-3 indented-list">
          <li><a [routerLink]="['/disclosures/history']" fragment="2008-04">April 2008</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2008-06">June 2008</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2011-11">November 2011</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2012-11">November 2012</a></li>
        
        </ul>
        <ul class="col-md-3 indented-list">
          <li><a [routerLink]="['/disclosures/history']" fragment="2013-09">September 2013</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2015-06">June 2015</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2016-04">April 2016</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2016-06">June 2016</a></li>
               
        </ul>
        <ul class="col-md-3 indented-list">
          <li><a [routerLink]="['/disclosures/history']" fragment="2017-07">July 2017</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2019-03">March 2019</a></li>
          <li><a [routerLink]="['/disclosures/history']" fragment="2022-04">April 2022</a></li>     
          <li><a [routerLink]="['/disclosures/history']" fragment="2023-05">May 2023</a></li>
        </ul>
      </div>
      <div class="row">
        <hr />
        <p id="2002-11">In November 2002, Index Portfolios 95 and 100 were moderately modified to have higher exposure
          to
          small and value equities throughout the world due to the high similarity of the 1999 versions of index
          portfolios 95 and 100 to index portfolio 90. According to the extensive research of Eugene Fama, Kenneth
          French
          and Jim Davis, utilizing data from the Center for Research of Security Prices (CRSP) over a 68 year period
          from
          July 1929 to June 1997, this change resulted in higher risk and return expectations than the previous versions
          of 95 and 100. (<a href="https://www.ifa.com/pdfs/academic-paper-characteristics-covariances-and-average-returns-1929-1997.pdf" target="_blank">see Characteristics,
            Covariances, and Average Returns: 1929-1997</a>)</p>
        <hr />
        <p id="2004-01">In January 2004 IFA changed the computer program setting to calculate annual rebalancing on the
          various indexes in the index portfolios. Previous to that they were rebalanced monthly. Annual rebalancing is
          closer to the actual rebalancing of client accounts, therefore it was adopted as the new method in January
          2005.
        </p>
        <hr />
        <p id="2006-06">
          In June of 2006 the historical monthly returns of the fifteen IFA indexes and the twenty IFA index portfolios
          were reconstructed to address the following issues:
        </p>
        <ul class="mx-3">
          <li>The availability of new and better sources of data for historical returns.</li>
          <li>The correction of errors in the prior data.</li>
          <li>Changes to the substitution of U.S. index data for international indexes in years prior to the existence
            of
            international data.</li>
        </ul>
        <p>The overall impact of these changes to the returns is small. To illustrate, the 79-year average annualized
          returns for Portfolios 5, 50, and 100 changed as follows:</p>
          <span class="text-center">Average Annualized Return from January 1927 to December 2005</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th></th>
              <th class="text-nowrap">Portfolio 5</th>
              <th class="text-nowrap">Portfolio 50</th>
              <th class="text-nowrap">Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>5.66%</td>
              <td>9.65%</td>
              <td>12.58%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>5.54%</td>
              <td>9.58%</td>
              <td>12.62%</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p id="2008-01">
          On January 1, 2008, DFA enacted a "soft close" of two of its funds. This meant that existing investors were
          able
          to add to their current positions, but new investors were not able to purchase positions in these funds. At
          that
          point, IFA stopped using the DFA U.S. Micro Cap Portfolio or the DFA U.S. Small Cap Value Portfolio for new
          investors (or for new non-taxable accounts added by existing investors).
        </p>
        <p>
          In place of Micro Cap, the DFA U.S. Small Cap Portfolio (DFSTX) was substituted. DFSTX includes holdings in
          micro cap companies. In place of Small Cap Value, the DFA U.S. Targeted Value Portfolio (DFFVX) was
          substituted.
          DFFVX has a slightly larger range of acceptable company sizes than Small Value. These changes applied to
          non-taxable accounts only. For taxable accounts, no changes were made.
        </p>
        <p>
          Since the returns on the website are primarily geared to prospective investors, the historical index portfolio
          returns shown were changed to reflect these substitutions. At the end of 2010, DFA re-opened these funds to
          new
          investors, and while IFA has started using them for both new and existing clients, IFA has opted not to use
          them
          in its historical index portfolio returns due to a high likelihood of them being closed again. Below is a
          table
          showing returns and standard deviation for the fifty-year period ending 12/31/2010.
        </p>
        <span class="text-center">January 1961 to December 2010</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th></th>
              <th class="text-nowrap">Annualized Return</th>
              <th class="text-nowrap">Standard Deviation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>P90 w/Small Cap & Targeted Value</th>
              <td>12.36%</td>
              <td>15.26%</td>
            </tr>
            <tr>
              <th>P90 w/Micro Cap & Small Value</th>
              <td>12.43%</td>
              <td>15.31%</td>
            </tr>
            <tr>
              <th>P100 w/Small Cap & Targeted Value</th>
              <td>13.10%</td>
              <td>16.16%</td>
            </tr>
            <tr>
              <th>P100 w/Micro Cap & Small Value</th>
              <td>13.24%</td>
              <td>16.37%</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p>In January 2008, IFA introduced three new indexes and eighteen socially responsible portfolios constructed
          from
          these three indexes and five pre-existing IFA indexes. The new indexes introduced were: IFA US Social Core 2
          Equity Index, IFA Emerging Markets Social Core Index, and IFA International Real Estate Index. All three use
          live DFA fund data as long as it has been available. Prior to live fund data, they use index data supplied by
          DFA modified for fund management fees.</p>
        <p id="2008-04">
          In April 2008, IFA introduced two new indexes and eighteen sustainability portfolios constructed from these
          two
          indexes and five pre-existing indexes. The new indexes introduced were: IFA US Sustainability Core 1 Equity
          and
          IFA International Sustainability Core Equity.
        </p>
        <hr />
        <p id="2008-06">
          On June 3, 2008, IFA started using the DFA Global Real Estate fund in place of the U.S. Real Estate fund for
          new
          clients. Historical returns for the IFA Real Estate Index were not changed.
        </p>
        <p>
          The historical monthly returns of the fifteen IFA indexes and the twenty IFA index portfolios were
          reconstructed
          in January of 2008 to address the following issues:

        </p>
        <ul class="mx-3">
          <li>The availability of new and better sources of data for historical returns.</li>
          <li>The substitution of U.S. Small Cap for U.S. Micro Cap.</li>
          <li>The substitution of U.S. Targeted Value for U.S. Small Value.</li>
        </ul>
        <p>The overall impact of these changes for 80 years is shown in the table below:</p>
        <span class="text-center">Average Annualized Return from January 1927 to December 2005</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th></th>
              <th class="text-nowrap">Portfolio 5</th>
              <th class="text-nowrap">Portfolio 50</th>
              <th class="text-nowrap">Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>5.51%</td>
              <td>9.43%</td>
              <td>12.38%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>5.27%</td>
              <td>8.89%</td>
              <td>11.66%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>

            <tr>
              <th>Old Standard Deviation</th>
              <td>4.49%</td>
              <td>15.00%</td>
              <td>26.56%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>4.18%</td>
              <td>13.01%</td>
              <td>22.66%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>

            <tr>
              <th>Old Reward-to-Risk Ratio</th>
              <td>1.23%</td>
              <td>0.63%</td>
              <td>0.47%</td>
            </tr>
            <tr>
              <th>New Reward-to-Risk Ratio</th>
              <td>1.26%</td>
              <td>0.68%</td>
              <td>0.51%</td>
            </tr>
          </tbody>
        </table>
        <p>The overall impact of these changes for 50 years is shown in the table below:</p>
        <span class="text-center">Average Annualized Return from January 1927 to December 2005</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th></th>
              <th class="text-nowrap">Portfolio 5</th>
              <th class="text-nowrap">Portfolio 50</th>
              <th class="text-nowrap">Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>6.99%</td>
              <td>10.97%</td>
              <td>14.68%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>6.78%</td>
              <td>10.63%</td>
              <td>14.25%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>3.22%</td>
              <td>8.71%</td>
              <td>15.32%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>3.59%</td>
              <td>8.64%</td>
              <td>14.85%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Reward-to-Risk Ratio</th>
              <td>2.17%</td>
              <td>1.26%</td>
              <td>0.96%</td>
            </tr>
            <tr>
              <th>New Reward-to-Risk Ratio</th>
              <td>1.89%</td>
              <td>1.23%</td>
              <td>0.96%</td>
            </tr>
          </tbody>
        </table>
        <p>All new tables and charts updated after January 1, 2008 will be based on the updated data series.</p>
        <hr />
        <p id="2011-11">
          In November 2011, IFA made a change to the index data used in its large growth and small growth indexes.
          Fama/French data was replaced with data supplied by Dimensional Fund Advisors via its Returns 2.2 program. For
          large growth, the difference in annualized return was about minus 1%. For small growth, the difference was
          about
          0.2%.
        </p>
        <hr />
        <p id="2012-11">
          In November 2012, IFA changed the allocations and the historical returns for its socially responsible
          portfolios
          to reflect the introduction of the DFA International Social Core Equity Portfolio (DSCLX). Prior to this, the
          international developed equity asset class was unavailable in a socially responsible implementation. As the
          table below shows, clients who were invested in the old allocation from the time it became available (January
          2008) likely did better than they would have done with the new allocation. The difference is not statistically
          significant, and it is IFA's advice that going forward having an exposure to international developed equities
          will provide a substantial diversification benefit to socially responsible investors.
        </p>
        <span class="text-center">Monthly Performance: 1/2008 - 10/2012</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th class="text-nowrap">Data Series</th>
              <th class="text-nowrap">Annualized Return (%)</th>
              <th class="text-nowrap">Annualized Standard Deviation (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>IFA Socially Responsible Portfolio 50 (Old)</th>
              <td>2.54</td>
              <td>13.89</td>
            </tr>
            <tr>
              <th>IFA Socially Responsible Portfolio 50 (New)</th>
              <td>2.02</td>
              <td>13.91</td>
            </tr>
            <tr>
              <th colspan="3"></th>
            </tr>
            <tr>
              <th>IFA Socially Responsible Portfolio 90 (Old)</th>
              <td>0.74</td>
              <td>24.16</td>
            </tr>
            <tr>
              <th>IFA Socially Responsible Portfolio 90 (New)</th>
              <td>-0.04</td>
              <td>24.19</td>
            </tr>

          </tbody>
        </table>
        <hr />
        <p id="2013-09">
          In September 2013, all new clients were placed into the NEW IFA Index Portfolios, and all existing clients
          will
          be given the option to transition to the new portfolios. IFA introduced the New IFA Index Portfolios which use
          the same equity allocation as Index Portfolio 100. Index Portfolio 100 was held the same as it has been since
          2000 and became the only 100 percent equity portfolio in the NEW Index Portfolios. The four fixed income
          indexes
          (25% each) remain the same as they have been since 2000 and will make up the fixed income allocation of all
          IFA
          index portfolios in the allocation equal to 100-New IP#. The name of each portfolio will now equate to the
          percentage of equities in the portfolio. For example, the new Index Portfolio 60 will be 60% equities and 40%
          fixed income.
        </p>
        <hr />
        <p id="2015-06">
          In June 2015, IFA introduced Profitability into the historical back-tested returns of the equity funds. IFA
          wanted to incorporate the new research completed by Fama/French that introduced profitability as its fourth
          factor in their asset pricing model. Profitability was back-tested by DFA back to 1975. As of 2015, NEW IFA
          Index Portfolios are referred to as IFA Index Portfolios. The previous allocations became the Original IFA
          Index
          Portfolios. In June 2013, Fama/French expanded upon the research first published by Robert Novy-Marx.
          Fama/French define profitability as a ratio of operating profitability minus interest expense to book value.
        </p>
        <p class="text-center"><b>Profitability = (Operating profit - Interest expenses)/book value</b></p>
        <p>Below is a comparison of IFA portfolio 100 pre-profitability vs IFA portfolio 100 including profitability.
          The
          overall impact of profitability for 39 years and 6 months is shown in the table below:</p>
        <span class="text-center">Monthly Performance : 1/1975 - 6/2015</span>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th class="text-nowrap">Data Series</th>
              <th class="text-nowrap">Annualized Return (%)</th>
              <th class="text-nowrap">Annualized Standard Deviation (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>IFA Index Portfolio 100 without Profitability</th>
              <td>14.80</td>
              <td>15.86</td>
            </tr>
            <tr>
              <th>IFA Index Portfolio 100 with Profitability</th>
              <td>14.91</td>
              <td>15.87</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p id="2016-04">
          In April 2016, IFA changed the allocations and the historical returns for its socially responsible portfolios
          to
          reflect the introduction of the DFA Social Fixed Income Portfolio (DSFIX). Prior to this, the fixed income
          asset
          class was composed of four unscreened bond funds as a social screened alternative did not exist. Although the
          duration of the unscreened bond fund mix was less than that of the socially screened bond fund, the increase
          in
          duration enhances the portfolio on a risk adjusted basis, and it enables IFA to provide a fully screened
          portfolio for socially responsible investors.
        </p>
        <hr />
        <p id="2016-06">
          In June 2016, IFA changed the back-testing IFA International value. Previously, IFA International Value was
          back-tested with longer, US-only data. This US-only data extended to 12/1969. Currently we now back-test
          Dimensional UK Large Value Index 7/1/1955 - 12/1969, providing us a better representation of international
          markets during that time period. Also, we changed to Fama/French International value from 1/1/1975 - 2/29/1994
          because that is a closer approximation of DFA International Value then the MSCI EAFE Value.
        </p>

        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th class="text-nowrap">OLD International Value Index Back-test
              </th>
              <th class="text-nowrap">NEW International Value Index Back-test</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 50%">
                <ul>
                  <li>Jan 1928 - Dec 1969: IFA US Large Value Index</li>
                  <li>Jan 1970 - Dec 1974: MSCI EAFE )(net Div) minus 3.58 bp/month</li>
                  <li>Jan 1975 - Jun 1993 = MSCI EAFE Value Gross - 3.58 bp/month </li>
                  <li>Jul 1993 - Feb 1994 = LWAS/DFA Int'l High BtM</li>
                  <li>Mar 1994 - Present = DFA International Value Fund (DFIVX)</li>
                </ul>
              </td>

              <td style="width: 50%">
                <ul>
                  <li>Jan 1928 - Jun 1955: IFA US Large Value Index</li>
                  <li>July 1955 - December 1969 Dimensional UK Large Value minus 3.58 bp/month</li>
                  <li>January 1970 - December 1974 MSCI EAFE Index (net Div) minus 3.58 bp/month</li>
                  <li>January 1975 - February 1994 Fama/French International Value minus 3.58 bp/month </li>
                  <li>Mar 1994 - Present = DFA International Value Fund (DFIVX)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <span class="text-center">Performance : 1/1928 - 5/2016</span>
        <table class="table table-bordered table-reponsive">
          
          <thead>
            <tr>
              <th>Data Series</th>
              <th>Annualized Return (%)</th>
              <th>Annualized Standard Deviation (%)</th>
              <th>Growth of $1</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>OLD International Value Index</td>
              <td>9.22</td>
              <td>22.77</td>
              <td>$2,427.97</td>
            </tr>
            <tr>
              <td>NEW International Value Index</td>
              <td>10.69</td>
              <td>23.26</td>
              <td>$7,945.61</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p id="2017-07">
          In July 2017, IFA changed its US Large Blend allocation from using DFA US Large Company (DFUSX) to Schwab S&P
          500 Index (SWPPX). US Large Blend will track SWPPX live fund data on a go forward basis. IFA has also amended
          its index back-test for US Large Blend to reflect the new allocation and its lower expenses.
        </p>
        <ul class="mx-3">
          <li>Old: January 1928 – December 1990: Dimensional US Large Cap Index minus 0.0083%/mo.</li>
          <li>New: January 1928 – December 1990: Dimensional US Large Cap Index minus 0.0025%/mo.</li>
        </ul>
        <hr />
        <p id="2019-03">
          As of March 2019, IFA updated the back-test of all IFA Indexes to include the latest Index data available, and
          amended all applicable index data with the corresponding funds most recent expense ratio. IFA International
          Value back-test was changed, removing MSCI EAFE Index (net Div) replacing it by extending Dimensional UK Large
          Value Index. IFA International Large Company Index back-test was changed, removing MSCI EAFE (net Div) and
          replacing it with Dimensional UK Market Index. IFA Emerging Markets Index back-test was changed, removing MSCI
          Emerging Markets Index (net Div) and replacing it by extending the 50% IFA International Value Index + 50% IFA
          International Small Index. IFA Investment Grade Index and IFA Social Fixed Income Index back-test were
          changed,
          removing IA SBBI US IT Govt. TR and replacing it with Long-Term Corporate Bond. IFA Nasdaq Index changed,
          extending the Fama/French US Small Growth Simulated Portfolio (ex. Utilities), Nasdaq Composite Index (ex
          div),
          and the Nasdaq Composite TR (XCMP) (Source: Morningstar), while replacing the Nasdaq 100 TR Index returns. IFA
          Large Growth Index back-test was changed to Dimensional US Large Cap High Price-to-Book Index. IFA U.S. Small
          Growth Index was changed, removing Dimensional Small Growth Index and replacing it with the Fama/French Small
          Growth Research Index. These changes to the back-test changed the returns of our IFA Index Portfolios and our
          IFA SRI (Socially Responsible Index) Portfolios. The overall impact of these changes for 91 years and two
          months
          is shown in the two tables below:
        </p>
        <span class="text-center">Annualized Returns from 1/1928 to 2/2019:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA Index Portfolio 5</th>
              <th>IFA Index Portfolio 50</th>
              <th>IFA Index Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>3.98%</td>
              <td>8.01%</td>
              <td>11.03%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>4.00%</td>
              <td>8.02%</td>
              <td>11.02%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>2.82%</td>
              <td>11.13%</td>
              <td>22.07%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>2.82%</td>
              <td>11.10%</td>
              <td>22.03%</td>
            </tr>
          </tbody>
        </table>
        <span class="text-center">Annualized Returns from 1/1928 to 2/2019:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA SRI Portfolio 5</th>
              <th>IFA SRI Portfolio 50</th>
              <th>IFA SRI Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>4.50%</td>
              <td>7.79%</td>
              <td>10.17%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>4.59%</td>
              <td>7.77%</td>
              <td>10.07%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>4.36%</td>
              <td>10.44%</td>
              <td>20.22%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>5.22%</td>
              <td>10.90%</td>
              <td>20.40%</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <p id="2022-04">As of April 2022, IFA updated the back-test of all IFA Indexes to include the latest Index data
          available, and amended all applicable index data with the corresponding funds most recent expense ratio. IFA
          U.S. Small Cap Value Index back-test was changed, replacing Dimensional US Targeted Value Index for
          Dimensional
          US Small Cap Value Index and DFA US Targeted Value (DFFVX) for DFA US Small Cap Value (DFSVX). IFA U.S. Small
          Cap Value Index back-test was also changed by updating the time series construction date range of Dimensional
          US
          Small Cap Value Index to 1/1/1928 - 3/31/1993, and of DFA US Small Cap Value (DFSVX) to 4/1/1993 - present.
          These changes to the back-test changed the returns of our IFA Index Portfolios and our IFA SRI (Socially
          Responsible Index) Portfolios. The overall impact of these changes for 94 years is shown in the two tables
          below:
        </p>
        <span class="text-center">Annualized Returns from 1/1928 to 12/2021:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA Index Portfolio 5</th>
              <th>IFA Index Portfolio 50</th>
              <th>IFA Index Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>3.92%</td>
              <td>8.04%</td>
              <td>11.17%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>3.94%</td>
              <td>8.10%</td>
              <td>11.21%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>2.79%</td>
              <td>11.08%</td>
              <td>22.01%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>2.81%</td>
              <td>11.51%</td>
              <td>22.87%</td>
            </tr>
          </tbody>
        </table>
        <span class="text-center">Annualized Returns from 1/1928 to 12/2021:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA SRI Portfolio 5</th>
              <th>IFA SRI Portfolio 50</th>
              <th>IFA SRI Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>4.61%</td>
              <td>7.86%</td>
              <td>10.26%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>4.65%</td>
              <td>7.89%</td>
              <td>10.29%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>5.19%</td>
              <td>10.83%</td>
              <td>20.30%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>5.19%</td>
              <td>10.89%</td>
              <td>20.41%</td>
            </tr>
          </tbody>
        </table>
         <!-- START MAY 2023 -->
        <hr />
        <p id="2023-05">As of March 2023, IFA updated the back-test of all IFA Indexes to include the latest Index data
          available, and amended all applicable index data with the corresponding funds most recent expense ratio.
          Several IFA Indexes introduced new funds starting 1/1/2023:
        </p>
          <ul class="indented-list">
            <li>IFA US Large Company Index: 1/1/2023 - present DFA US Equity EFT (DFUS) replaces Schwab S&amp;P 500 (SWPPX)</li>
            <li>IFA US Large Cap Value Index: 1/1/2023 - present DFA US Large Cap Value ETF (DFLV) replaces DFA US Large Cap Value (DFLVX)</li>
            <li>IFA US Small Cap Index: 1/1/2023 - present DFA US Small Cap ETF (DFAS) replaces DFA US Small Cap (DFSTX)</li>
            <li>IFA US Small Cap Value Index: 1/1/2023 - present Dimensional US Small Cap Value ETF (DFSV) replaces DFA US Small Cap Value (DFSVX)</li>
            <li>IFA Global REIT Index: 1/1/2023 - present DFA Global Real Estate ETF (DFGR) replaces DFA Global Real Estate Securities (DFGEX)</li>
            <li>IFA International Value Index: 1/1/2023 - present Dimensional International Value ETF (DFIV) replaces DFA International Value (DFIVX)</li>
            <li>IFA International Small Company Index: 1/1/2023 - present DFA International Small Cap ETF (DFIS) replaces DFA International Small Company (DFISX)</li>
            <li>IFA International Small Cap Value Index: 1/1/2023 - present Dimensional US Small Cap Value ETF (DFSV) replaces DFA US Small Cap Value (DFSVX)</li>
            <li>IFA Emerging Markets Index: 1/1/2023 - present DFA Emerging Core Equity Market ETF (DFAE) replaces DFA Emerging Markets (DFEMX)</li>
            <li>IFA Emerging Markets Value Index: 1/1/2023 - present DFA Emerging Markets Value ETF (DFEV) replaces DFA Emerging Markets Value (DFEVX)</li>   
            <li>IFC Global REIT Index: 1/1/2023 - present DFA Global Real Estate ETF (DFGR) replaces DFA Global Real Estate Securities (DFGEX)</li>
          </ul>
      
          <p>
          IFA International Value Index added Dimensional International Large Value Index from 1/1/1990 to 2/28/1994 which updates the ending date for Fama-French International Value Index on 12/31/1989.
        </p>
        <p>
          These changes to the back-test changed the returns of our IFA Index Portfolios and our IFA SRI (Socially
          Responsible Index) Portfolios. The overall impact of these changes for 95 years is shown in the two tables
          below:
        </p>
        <span class="text-center">Annualized Returns from 1/1928 to 12/2022:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA Index Portfolio 5</th>
              <th>IFA Index Portfolio 50</th>
              <th>IFA Index Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>3.84%</td>
              <td>7.91%</td>
              <td>10.94%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>3.86%</td>
              <td>7.91%</td>
              <td>10.93%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>2.82%</td>
              <td>11.51%</td>
              <td>22.87%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>2.82%</td>
              <td>11.51%</td>
              <td>22.87%</td>
            </tr>
          </tbody>
        </table>
        <span class="text-center">Annualized Returns from 1/1928 to 12/2022:</span>
        <table class="table table-bordered table-responsive">
          
          <thead>
            <tr>
              <th></th>
              <th>IFA SRI Portfolio 5</th>
              <th>IFA SRI Portfolio 50</th>
              <th>IFA SRI Portfolio 100</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Old Return</th>
              <td>4.44%</td>
              <td>7.60%</td>
              <td>9.93%</td>
            </tr>
            <tr>
              <th>New Return</th>
              <td>4.44%</td>
              <td>7.60%</td>
              <td>9.93%</td>
            </tr>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th>Old Standard Deviation</th>
              <td>5.28%</td>
              <td>10.96%</td>
              <td>20.44%</td>
            </tr>
            <tr>
              <th>New Standard Deviation</th>
              <td>5.28%</td>
              <td>10.96%</td>
              <td>20.44%</td>
            </tr>
          </tbody>
        </table>
        <!-- END MAY 2023 -->
        <hr />
        <p><a href="https://www.ifa.com/disclosures">See full IFA disclosures here.</a></p>
        <hr />
        <p id="notes">Notes:</p>
        <ul>
          <li>Robert Novy-Marx white paper:<br />
            <a href="http://rnm.simon.rochester.edu/research/OSoV.pdf"
              target="_blank">http://rnm.simon.rochester.edu/research/OSoV.pdf</a>
          </li>
          <li>Fama French white paper on profitability:<br />
            <a href="http://papers.ssrn.com/sol3/papers.cfm?abstract_id=2287202"
              target="_blank">http://papers.ssrn.com/sol3/papers.cfm?abstract_id=2287202</a>
          </li>
          <li>IFA Thoughts:<br />
            <a routerLink="/articles/profitability_four-factor_model"
              target="_blank">https://www.ifa.com/articles/profitability_four-factor_model/</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
      <app-side-nav-disclosures></app-side-nav-disclosures>
    </div>
  </div>
</div>
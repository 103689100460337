<app-jumbotron title="Disclosures and Policies"
  icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>

<div class="white_graph">
    <div class="container">
        <div class="row d-flex">
            <div class="col-lg-9 col-md-12 order-lg-1 order-2 mt-4">
                <h2 class="font-600 font-30">Index Fund Advisors, Inc. (IFA) Business Continuity Plan Statement</h2>

    <p>IFA has developed a Business Continuity Plan regarding how we will respond to events that may significantly disrupt our business. Since the timing and impact of disasters and disruptions is unpredictable, IFA must be flexible in responding to actual events as they occur. With that in mind, IFA is providing this information regarding its business continuity plan.</p>

    <h3>Contacting Us</h3>
    <p>If after a significant business disruption you cannot contact IFA as you usually do at <a href="tel:+1-{{globals.phoneLocal}}">{{globals.phoneLocal}}</a> or <a href="tel:+1-{{globals.phoneTollFree}}">{{globals.phoneTollFree}}</a>, clients should phone IFA's emergency phone number <a href="tel:+1-{{globals.phoneEmergency}}">({{globals.phoneEmergency}})</a> or go to our web site at <a routerLink="/">www.ifa.com</a>.</p>

    <h3>Our Business Continuity Plan</h3>
    <p>IFA plans to quickly recover and resume business operations after a significant business disruption and respond by safeguarding our employees and property, making a financial and operational assessment, protecting the firm's books and records, and allowing our customers to transact business. In short, our business continuity plan is designed to permit our firm to resume operations as quickly as possible, given the scope and severity of the significant business disruption.</p>
    <p>Our business continuity plan addresses: data backup and recovery; all mission critical systems; financial and operational assessments; alternative communications with customers, employees, and regulators; alternate physical location of employees; critical supplier, contractor, bank and counter-party impact; regulatory reporting; and assuring our customers prompt service if we are unable to continue our business.</p>

    <h3>Varying Disruptions</h3>
    <p>The severity of the disruption can vary from minimal to severe. In a disruption to only our main office location or the building housing our main office location, we will transfer our operations to a local site and expect to recover and resume business within one day. In a disruption affecting our business district, city, or region, we will transfer our operations to a site outside of the affected area, and recover and resume business within three days. In either situation, we plan to continue in business and will notify clients through our web site (<a routerLink="/">www.ifa.com</a>) or through an outgoing message on our emergency phone number <a href="tel:+1-{{globals.phoneEmergency}}">({{globals.phoneEmergency}})</a> regarding how to contact us.</p>



    <h3>For More Information</h3>
    <p>If you have questions about IFA's business continuity planning, please contact Michelle Ojeda, Co-Chief Compliance Officer, at michelle&#64;ifa.com or <a href="tel:+1-{{globals.phoneLocal}}">{{globals.phoneLocal}}</a>.</p>
            </div>
            <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
                <app-side-nav-disclosures></app-side-nav-disclosures>
            </div>

        </div>
    </div>
</div>
<app-jumbotron title="Disclosures and Policies"
  icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>

<div class="white_graph">
    <div class="container">
        <div class="row d-flex">
            <div class="col-lg-9 col-md-12 mt-4 order-lg-1 order-2">
                <h2>Other Information IFA Considers to be Helpful to Potential Clients</h2>

                <p>It is IFA's advice that the value of having a longer time series exceeds the concerns of index substitutions over the 1928 to present period. Due to the very high standard deviations of returns (22.00%)* a 40 year or more sample size of data is recommended to obtain a T-statistic of 2, that allows a conclusion at a 97.5% or higher level of certainty. In other words, in IFA's opinion, smaller sample sizes introduce larger errors than the errors introduced by stitching together indexes and live data over time. This is the advice IFA provides to its clients.</p>
                <p>Client portfolios are monitored and rebalanced, taking into consideration risk exposure consistency, transaction costs, and tax ramifications to maintain target asset allocations as shown in the Index Portfolios.</p>
                
                <p>Clients' accounts will be rebalanced depending on the fluctuation of the asset classes and the cash flow activity of the client. It is IFA's opinion that the assumption of first of the year annual rebalancing is a reasonable approximation to reality.</p>
                <p>IFA is not paid any brokerage commissions, sales loads, 12b-1 fees, or any form of compensation from any mutual fund company or broker dealer. The only source of compensation from client investments is obtained from asset based advisory fees paid by the client. More information about advisory fees, expenses, no-load mutual fund fees, prospectuses for no-load index mutual funds, brokerage and custodian fees can be found at <a routerLink="/fees" target="_blank">www.ifa.com/fees</a> Not all IFA clients follow our recommendations, and depending on unique and changing client and market situations, we may customize the construction and implementation of the index portfolios for particular clients, including the use of tax-managed mutual funds, tax-loss-harvesting techniques and rebalancing frequency and precision. In taxable accounts, IFA uses tax-managed index funds to manage client assets.</p>    


                <div class="disclosure mt-4">
                    <a class="text-decoration-none font-d-blue" href="https://www.ifa.com/portfolios/100/#3">*Standard deviation based on {{currentYear - 1928}} years of Index Portfolio 100</a>
                </div>

            </div>
            <div class="col-lg-3 col-md-12 d-sm-block d-none p-4 order-lg-2 order-1">
                <app-side-nav-disclosures></app-side-nav-disclosures>
            </div>

        </div>
    </div>
</div>
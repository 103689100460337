import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-side-nav-disclosures',
  templateUrl: './side-nav-disclosures.component.html',
  styleUrls: ['./side-nav-disclosures.component.scss'],
  imports:[RouterModule,CommonModule]
})
export class SideNavDisclosuresComponent implements OnInit {
  globals: Globals;

  constructor(globals: Globals) { 
    this.globals = globals;
  }

  ngOnInit(): void {
  }

}

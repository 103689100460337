<app-jumbotron title="Terms and Conditions of Use" icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>
<div class="container">
    <div class="row d-flex">
        <div class="col-lg-9 col-md-12 order-lg-1 order-2 p-4">

            <h2 class="b">Conditions of Use</h2>

            <p class="b">By using this site, you signify your agreement to the following conditions of use without limitation or qualification. Please read these conditions carefully before using this site.</p>
            <p class="b">Index Fund Advisors, Inc. ("IFA") may at any time revise these conditions of use by updating this page. You are bound by any future revisions and should therefore periodically visit this page to review the terms and conditions to which you are bound.</p>
        
            <h3>Jurisdictional Issues</h3>
            <p>The information contained on this web site is not an offer to sell or a solicitation to buy any security or product. No security or product is offered or will be sold in any jurisdiction in which such offer or solicitation, purchase or sale would be unlawful under the securities, insurance or other laws of such jurisdiction.</p>
        
            <h3>Limitation of Liability</h3>
            <p>While IFA uses reasonable efforts to include accurate and current information on this web site, errors or omissions sometimes occur. IFA makes no warranties or representations as to the accuracy of the information contained on this web site. Under no circumstances shall IFA nor any party involved in creating, producing, or delivering the web site be liable for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this web site, even if a IFA-authorized representative has been advised of the possibility of such damages. Applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
            <p>At certain places on this site, live 'links' to other Internet addresses may be accessed. Such external internet addresses contain information created, published, maintained, or otherwise posted by institutions or organizations independent of IFA.  IFA does not endorse, approve, certify, or control these external internet addresses and does not guarantee or assume responsibility for the accuracy, completeness, efficacy, timeliness, or correct sequencing of information located at such addresses. Use of any information obtained from such addresses is voluntary, and reliance on it should only be undertaken after an independent review of its accuracy, completeness, efficacy, and timeliness. Reference therein to any specific commercial product, process, or service by trade name, trademark, service mark, manufacturer, or otherwise does not constitute or imply endorsement, recommendation, or favoring by Index Fund Advisors. IFA also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing in the web site or your downloading of any materials, data, text, images, video, or audio from this web site.</p>
        
            <h3>Restrictions on Use of Materials</h3>
            <p><span class="b">This site is for the use of clients and potential clients of Index Fund Advisors, Inc. and IFA Network Members.</span>  It is not to be used by any other investment advisor or investment professional as an information/marketing materials source, asset allocator, risk capacity or risk tolerance assessment, or for any other purpose. The right to download, store and/or output any material on this internet site, or the Index Funds: The 12-Step Program for Active Investors eBook, or any other publication by IFA, is granted for viewing use only and this grant only applies to clients and potential clients of IFA and the IFA Network Members.</p>
            <p>Except as otherwise permitted by IFA, materials from www.ifa.com or any web site owned, operated, licensed or controlled by IFA may not be copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way. You may download material displayed on this web site for non-commercial, personal use only, provided you also retain all copyright and other proprietary notices contained on the materials. You may not distribute, modify, transmit, reuse, report or use the content of this web site for public or commercial purposes, including the text, images, audio and video, without IFA's prior written permission.   Reproduction or editing by any means, mechanical or electronic, in whole or in part, without the express written permission of IFA is strictly prohibited and subject to prosecution under U.S. and international copyright and trademark laws.</p>
        
            <h3>Trademarks and Copyrights</h3>
            <p>All trademarks, service marks, trade names, logos, and icons are proprietary to IFA. Nothing contained on the web site should be construed as granting any license or right to use any trademark displayed on this web site without the prior written permission of IFA or such third party that may own the trademarks displayed on this web site. Your use of the trademarks displayed on this web site, or any other content on this web site, except as provided herein, is strictly prohibited.</p>
            <p>Images displayed on this web site are either the property of, or used with permission by, IFA. The use of these images by you, or anyone else authorized by you, is prohibited unless specifically permitted herein. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.</p>
            <p>Inquiries concerning use of IFA's trademarks, service marks, trade names, logos, icons, copyrights or other intellectual property should be addressed to Index Fund Advisors, Inc., Attention: Compliance Department, 19200 Von Karman Avenue Suite 150, Irvine, CA 92612.</p>
            <a name="terms" id="terms"></a>
            <h2 class="font-30 b" id="term_of_use">Terms of Use</h2>
        <p>This site is for the use of clients and potential clients of Index Fund Advisors, Inc. and the <a routerLink="/network-members">IFA Promoters</a>. It is not to be used by any other investment advisor or investment professional as an information/marketing materials source, asset allocator, risk capacity or risk tolerance assessment, or for any other purpose. If your financial advisor is using this site without a license from Index Fund Advisors, Inc., they are violating our copyright and their ethics are highly in question. The right to download, store and/or output any material on this internet site, or the <u>Index Funds: The 12-Step Program for Active Investors</u> eBook, is granted for viewing use only and this grant only applies to clients and potential clients of Index Fund Advisors, Inc. and the IFA Network Members. Reproduction or editing by any means, mechanical or electronic, in whole or in part, without the express written permission of Index Fund Advisors, Inc. is strictly prohibited and subject to prosecution under U.S. and International copyright and trademark laws.</p>
        <p>DISCLAIMER: THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED, AS TO ACCURACY, COMPLETENESS, OR RESULTS OBTAINED FROM ANY INFORMATION POSTED ON THIS OR ANY LINKED INTERNET SITE. At certain places on this Index Fund Advisors Internet site, live 'links' to other Internet addresses can be accessed. Such external Internet addresses contain information created, published, maintained, or otherwise posted by institutions or organizations independent of Index Fund Advisors. Index Fund Advisors does not endorse, approve, certify, or control these external Internet addresses and does not guarantee or assume responsibility for the accuracy, completeness, efficacy, timeliness, or correct sequencing of information located at such addresses. Use of any information obtained from such addresses is voluntary, and reliance on it should only be undertaken after an independent review of its accuracy, completeness, efficacy, and timeliness. Reference therein to any specific commercial product, process, or service by trade name, trademark, service mark, manufacturer, or otherwise does not constitute or imply endorsement, recommendation, or favoring by Index Fund Advisors. Your use of this site is acknowledgment that you have read and understood the disclaimer. Past performance does not guarantee future results.</p>
        <p>WARNING: Past performance does not guarantee future results. Investment returns and principal value will fluctuate, so that investors' shares, when sold, may be worth more or less than their original cost. Investing in any mutual fund, index or actively managed, does not guarantee that an investor will make money, avoid losing capital, or indicate that the investment is risk-free. Actively managed funds sometimes outperform index funds. You just don't know in advance which actively managed fund will outperform the appropriate index. Just because a mutual fund is an index mutual fund, it does not guarantee a performance superior to an actively managed mutual fund. There are no absolute guarantees in investing. When reviewing any back-tested performance information on this internet site, please read the <a routerLink="/disclosures">Disclosure for Back-tested Performance Information</a>. For a copy of the Index Fund Advisors, Inc. Privacy Policy, <a href="https://www.ifa.com/pdfs/privacypolicy.pdf">click here</a>.</p>
        
        
        </div>
        <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
            <app-side-nav-disclosures></app-side-nav-disclosures>
          </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { IndexDataComponent } from './index-data/index-data.component';
import { DisclosuresComponent } from './disclosures.component';
import { HypotheticalBackTestedComponent } from './hypothetical-back-tested/hypothetical-back-tested.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { ContinuityComponent } from './continuity/continuity.component';
import { DisclosureChartsComponent } from './charts/charts.component';
import { HistoryComponent } from './history/history.component';
import { RiskComponent } from './risk/risk.component';
import { TermsComponent } from './terms/terms.component';
import { SideNavDisclosuresComponent } from './_components/side-nav-disclosures/side-nav-disclosures.component';

import { SafePipe } from 'src/app/pipes/safe.pipe';

import { IndexDescriptionsComponent } from './index-descriptions/index-descriptions.component';

import { JumbotronComponent } from '../_components/jumbotron/jumbotron.component';
import { SVGSpriteComponent } from '../_components/svg-sprite/svg-sprite.component';
import { ChartEmbedComponent } from '../_components/chart-embed/chart-embed.component';
import { SvgDefinitionsComponent } from '../_components/svg-definitions/svg-definitions.component';
import { DisclosuresNavComponent } from '../_components/sideNavigations/disclosures/disclosures-nav/disclosures-nav.component';


@NgModule({
  declarations: [
   
    DisclosuresComponent,
  ],
  imports: [
    CommonModule,
    SafePipe,
    ChartEmbedComponent,
    DisclosuresNavComponent,
    IndexDataComponent,
    SideNavDisclosuresComponent,
    SvgDefinitionsComponent,
    SVGSpriteComponent,
    JumbotronComponent,
    
    RouterModule.forChild([{
      path: '',      
      component:DisclosuresComponent,
      children: [
                 { path:    '',                pathMatch:'full',   component:HypotheticalBackTestedComponent },
                 { path:    'additional-info', pathMatch:'full',   component:AdditionalInfoComponent },
                 { path:    'continuity',      pathMatch:'full',   component:ContinuityComponent  },
                 { path:    'charts',          pathMatch:'full',   component:DisclosureChartsComponent  },
                 { path:    'history',         pathMatch:'full',   component:HistoryComponent  },
                 { path:    'index-data',      pathMatch:'full',   component:IndexDescriptionsComponent  },
                 { path:    'risk',            pathMatch:'full',   component:RiskComponent  },
                 { path:    'terms',           pathMatch:'full',   component:TermsComponent  }
             ]
    }]),
    RouterOutlet,
  ]
})
export class DisclosuresModule { }

<app-jumbotron title="Disclosures and Policies"
  icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>

<div class="white_graph">
    <div class="container">
        <div class="d-flex row">
            <div class="col-lg-9 col-md-12 order-lg-1 order-2 mt-4">
                <h2>Risk Disclosure</h2>

                <p>WARNING: Past performance does not guarantee future results. Investment returns and principal value will fluctuate, so that investors' shares, when sold, may be worth more or less than their original cost. Investing in any mutual fund, index or actively managed, does not guarantee that an investor will make money, avoid losing capital, or indicate that the investment is risk-free. Actively managed funds sometimes outperform index funds. An investor cannot determine in advance which actively managed fund will outperform the appropriate index. Just because a mutual fund is an index mutual fund, it does not guarantee a performance superior to an actively managed mutual fund. There are no absolute guarantees in investing. When reviewing any back-tested performance information on this internet site, please read the <a routerLink="/disclosures">Disclosure for Back-tested Performance Information</a>.</p>


            </div>
            <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
                <app-side-nav-disclosures></app-side-nav-disclosures>
            </div>

        </div>
    </div>
</div>
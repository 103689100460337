import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';


import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-disclosures-nav',
  templateUrl: './disclosures-nav.component.html',
  styleUrls: ['./disclosures-nav.component.scss'],
  imports: [
      CommonModule, 
      RouterModule
    ],
})
export class DisclosuresNavComponent implements OnInit , AfterViewInit
{
  globals: Globals;
  discDrop:any|undefined;

  constructor(globals: Globals, @Inject(PLATFORM_ID) private platformId: Object) { 
    this.globals = globals;
  }
    
  async ngAfterViewInit() 
  {
    if (isPlatformBrowser(this.platformId)) {  
      // Safe to use document here  
      const bootstrap = await import("bootstrap");
      this.discDrop = new bootstrap.Dropdown(document.getElementById("discDrop") as HTMLElement);
    }
  }
    
  ngOnInit() {  }

  toggleDropDown()
  {
    this.discDrop?.toggle();
  }

}

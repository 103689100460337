<ul class="nav sticky-top">
    <li><a routerLink="/disclosures/index-data">Index Descriptions</a></li>
    <li><a routerLink="/disclosures">Disclosures for the Hypothetical Back-tested Performance of Model Index Portfolios and Indexes</a></li>
    <li><a routerLink="/disclosures/history">History of Changes</a></li>
    <li><a routerLink="/disclosures/charts">Supplemental Charts</a></li>
    <!-- <li><a routerLink="/disclosures/terms">Conditions of Use</a></li> -->
    <li><a routerLink="/disclosures/terms">Terms and Conditions of Use</a></li>    
    <li><a routerLink="/disclosures/risk">Risk Disclosure</a></li>
    <li><a routerLink="/disclosures/continuity">Business Continuity Plan Statement</a></li>
    <li><a routerLink="/disclosures/additional-info">Additional Information</a></li>
    <li><a href="https://www.ifa.com/pdfs/privacypolicy.pdf" target="_blank">Privacy Policy</a></li>
    <li><a href="{{globals.formADVUrl}}" target="_blank">Form ADV</a></li>
</ul>
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JumbotronComponent } from '../../_components/jumbotron/jumbotron.component';
import { SideNavDisclosuresComponent } from '../_components/side-nav-disclosures/side-nav-disclosures.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  imports:[CommonModule,JumbotronComponent,SideNavDisclosuresComponent,RouterModule]
})
export class TermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SideNavDisclosuresComponent } from '../_components/side-nav-disclosures/side-nav-disclosures.component';
import { JumbotronComponent } from '../../_components/jumbotron/jumbotron.component';
import { RouterModule } from '@angular/router';
import { ChartEmbedComponent } from '../../_components/chart-embed/chart-embed.component';

@Component({
  selector: 'app-disclosure-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  imports:[CommonModule,SideNavDisclosuresComponent,JumbotronComponent,RouterModule,ChartEmbedComponent]
})
export class DisclosureChartsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SideNavDisclosuresComponent } from '../_components/side-nav-disclosures/side-nav-disclosures.component';
import { JumbotronComponent } from '../../_components/jumbotron/jumbotron.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
  imports:[CommonModule,SideNavDisclosuresComponent,JumbotronComponent,RouterModule]
})
export class AdditionalInfoComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}

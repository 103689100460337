import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { Data2Service } from 'src/app/services/data2/data2.service';
import { JumbotronComponent } from '../../_components/jumbotron/jumbotron.component';
import { SideNavDisclosuresComponent } from '../_components/side-nav-disclosures/side-nav-disclosures.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-hypothetical-back-tested',
  templateUrl: './hypothetical-back-tested.component.html',
  styleUrls: ['./hypothetical-back-tested.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
  imports:[CommonModule,JumbotronComponent,SideNavDisclosuresComponent,RouterModule]
})
export class HypotheticalBackTestedComponent {
  disclosure$:Observable<any>;
  constructor(public data2Service:Data2Service) {
    //Declarative Pattern, No Change Detection, No Subscribe / Unsubscribe
    this.disclosure$ = this.data2Service.html_snippets_ifa_disclosure$().pipe(
      catchError(err=>{
        return "There was an error getting the disclosures. Please try again later.";
      }),
      map(x=>{
          console.log("ifa_disclosure observable",x);
        return x[0].html;
      })
    );

   }
 
  
}

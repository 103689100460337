<app-jumbotron title="Disclosures and Policies"
  icon="/assets/images/icons/menu/disclosures.png"></app-jumbotron>

<div class="white_graph">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-12 order-lg-1 order-2 mt-4">


                <div class="mt-1">
                    <h1 class="bree-font">Supplemental Charts</h1>
                </div>

                <div class="mt-3">
                    <div id="1" class="fig-num pt-4 pb-2"><em class="fig">Figure 1</em>
                        <a routerLink="./" fragment="1" class="permalink">(permalink)</a>
                    </div>
                    <h3> Time Series Construction of the IFA Indexes </h3>
                        <a name="timeseries" id="timeseries"></a>
                    <app-chart-embed [id]="30"></app-chart-embed>
                </div>

                <div class="mt-3">
                    <div id="2" class="fig-num pt-4"><em class="fig">Figure 2</em>
                        <a routerLink="./" fragment="2" class="permalink">(permalink)</a>
                    </div>
                    <app-chart-embed [id]="342"></app-chart-embed>
                </div>

                <div class="mt-3">
                    <div id="3" class="fig-num pt-4"><em class="fig">Figure 3</em>
                        <a routerLink="./" fragment="3" class="permalink">(permalink)</a>
                    </div>
                    <app-chart-embed [id]="170"></app-chart-embed>
                </div>

                <div class="mt-3">
                    <div id="4" class="fig-num pt-4"><em class="fig">Figure 4</em>
                        <a routerLink="./" fragment="4" class="permalink">(permalink)</a>
                    </div>
                    <h3> DFA Fund and Index Comparison </h3>
                    <a name="dfafunds" id="dfafunds"></a>
                    <app-chart-embed [id]="175"></app-chart-embed>
                </div>
                <div class="mt-3">
                    <div id="5" class="fig-num pt-4"><em class="fig">Figure 5</em>
                        <a routerLink="./" fragment="5" class="permalink">(permalink)</a>
                    </div>
                    <h3>The Evolution of IFA Index Portfolios </h3>
                    <a name="IFA_evolution" id="IFA_evolution"></a>
                    <app-chart-embed [id]="132"></app-chart-embed>
                </div>
                <div class="mt-3">
                    <div id="6" class="fig-num pt-4"><em class="fig">Figure 6</em>
                        <a routerLink="./" fragment="6" class="permalink">(permalink)</a>
                    </div>
                    <app-chart-embed [id]="133"></app-chart-embed>
                </div>
                <div class="mt-3">
                    <div id="7" class="fig-num pt-4"><em class="fig">Figure 7</em>
                        <a routerLink="./" fragment="7" class="permalink">(permalink)</a>
                    </div>
                    <app-chart-embed [id]="176"></app-chart-embed>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 d-sm-block d-none order-lg-2 order-1 p-4">
                <app-side-nav-disclosures></app-side-nav-disclosures>
            </div>
        </div>
    </div>
</div>
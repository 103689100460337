import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { SideNavDisclosuresComponent } from '../_components/side-nav-disclosures/side-nav-disclosures.component';
import { JumbotronComponent } from '../../_components/jumbotron/jumbotron.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-continuity',
  templateUrl: './continuity.component.html',
  styleUrls: ['./continuity.component.scss'],
  imports:[CommonModule,SideNavDisclosuresComponent,JumbotronComponent,RouterModule]
})
export class ContinuityComponent implements OnInit {

  constructor(public globals: Globals) { 

  }

  ngOnInit(): void {
  }

}

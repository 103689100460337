<div class="mx-auto d-sm-none d-md-none d-lg-none width100">
  <button class="dropdown-toggle mx-auto d-block sub-nav-buttons x" id="discDrop" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" (click)="toggleDropDown()">
    Disclosures and Policies
</button>
    <ul class="dropdown-menu menu" style="z-index: 50000">
      <li><a class="disclosureNavLink" routerLink="/disclosures/index-data" target="_self">Index Descriptions</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures" target="_self">Disclosures for the Hypothetical Back-tested Performance of Model Index Portfolios and Indexes</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures/history" target="_self">History of Changes</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures/charts" target="_self">Supplemental Charts</a></li>
      <!-- <li><a class="disclosureNavLink" routerLink="/disclosures/terms" target="_self">Conditions of Use</a></li> -->
      <li><a class="disclosureNavLink" routerLink="/disclosures/terms" target="_self">Terms and Conditions of Use</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures/risk" target="_self">Risk Disclosure</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures/continuity" target="_self">Business Continuity Plan Statement</a></li>
      <li><a class="disclosureNavLink" routerLink="/disclosures/additional-info" target="_self">Additional Information</a></li>
      <li><a class="disclosureNavLink" routerLink="/pdfs/privacypolicy.pdf" target="_blank">Privacy Policy</a></li>
      <li><a class="disclosureNavLink" href="{{globals.formADVUrl}}" target="_blank">Form ADV</a></li>
    </ul>
</div> 


<app-jumbotron title="Index Descriptions" icon="/assets/images/icons/menu/index-descriptions.png">
</app-jumbotron>


<div class="container">
    <div class="row">
        <div class="col-lg-9 col-md-12 order-lg-1 order-2 p-4">
            <app-index-data></app-index-data>
        </div>
        <div class="col-lg-3 col-md-12 d-sm-block d-none  order-lg-2 order-1 p-4">
            <app-side-nav-disclosures></app-side-nav-disclosures>
        </div>
    </div>
</div>